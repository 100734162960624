import React, { useState, useEffect, useRef } from "react"
import Amplify from "aws-amplify"
import { API } from "aws-amplify"
import { validate } from 'react-email-validator'
import Fade from "react-reveal/Fade"
import { BallTriangle } from 'react-loader-spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { SnackBarAlert, SnackBarAlertError } from '../SnackBarAlert'
import feastmap from '../../images/feastmap.png'
import download from '../../images/download.png'
import { DOWNLOAD_LINK } from "../../constants"

import awsExport from '../../aws-exports'
Amplify.configure(awsExport)

const Hero = () => {

  const [email, setEmail] = useState('');
  const lastSavedEmail = useRef(null);
  const [didSignUp, setDidSignUp] = useState(false);

  const [alertOpen, setAlertOpen] = useState(false);
  const alertMessage = useRef('Thank you for your interest — you will be notified soon!');
  const [alertOpenError, setAlertOpenError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const currEmail = email.replace(/\s+/g, '').toLowerCase();

    if (lastSavedEmail.current === currEmail) {
      alertMessage.current = 'You have already signed up!';
      setAlertOpen(true);
      return
    }

    if (!validate(currEmail)) {
      alertMessage.current = 'Please enter a valid email address';
      setAlertOpenError(true);
      return
    }
    console.log(currEmail);

    const data = {
      body: { email: currEmail }
    };
    setIsLoading(true);
    try {
      const res = await API.post('feastwebapi', '/items', data);
      if (res.status !== 200) {
        throw new Error(res.err);
      }
      lastSavedEmail.current = currEmail;
      alertMessage.current = 'Thank you for your interest — you will be notified soon!';
      setDidSignUp(true)
      setAlertOpen(true)
    } catch (err) {
      console.log('Error saving email: ', err)
      alertMessage.current = 'There was an error saving your email. Please try again.';
      setAlertOpenError(true)
    }
    setIsLoading(false);
  };

  useEffect(() => {
    const img = new Image();
    img.src = feastmap;
  }, []);

  return (
    <Fade bottom>
      <div className="hero">
        <SnackBarAlert
          alertOpen={alertOpen}
          setAlertOpen={setAlertOpen}
          message={alertMessage.current}
        />
        <SnackBarAlertError
          alertOpen={alertOpenError}
          setAlertOpen={setAlertOpenError}
          message={alertMessage.current}
        />
        <div className="content">
          <p className="intro-text">Introducing <span>Feast</span> —</p>
          <h1 className="intro-text">Food with Friends</h1>
          <p className="description">
            Discover, review, and share your favorite places to eat with your friends.
          </p>
          <form onSubmit={handleSubmit}>
            <label>
              <div className="email-container">
                <span className="email-icon"><FontAwesomeIcon icon={faEnvelope} color="#5C6C7F" /></span>
                <input
                  className="email-input"
                  type="text"
                  placeholder="Sign up for email updates"
                  autoCapitalize="off"
                  onFocus={() => setDidSignUp(false)}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </label>
            {isLoading
              && <div className="submit">
                <BallTriangle
                  wrapperClass="loader"
                  height="20"
                  color='white'
                  ariaLabel='loading'
                />
              </div>}
            {!isLoading
              && <input
                className={didSignUp ? "submit submitted-color" : "submit"}
                type="submit"
                value={didSignUp ? "Signed Up!" : "Sign Up"}
              />
            }
          </form>

          <a className="download-wrapper" href={DOWNLOAD_LINK} target="_blank" rel="noopener noreferrer">
            <img className="download" src={download} alt="download"></img>
          </a>
          <p className="subtitle">
            Coming soon to Google Play
          </p>
        </div>
        <div className="image-wrapper">
          <img className="image" src={feastmap} alt="about"></img>
        </div>
        <h2 className="intro-text-mobile offset-margin">Food with Friends</h2>
        <p className="intro-text-mobile">Introducing <span>Feast</span> —</p>
      </div>
    </Fade>
  )
}

export default Hero
