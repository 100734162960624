import * as React from "react"

const OrangeCircle = (props) => {
  return (
    <svg
      width={1070}
      height={1025}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient
          x1="32.866%"
          y1="36.271%"
          x2="96.027%"
          y2="79.946%"
          id="prefix__a"
        >
          <stop stopColor="#FFC529" offset="0%" />
          <stop stopColor="#FE724C" offset="100%" />
        </linearGradient>
        <filter
          x="-13.3%"
          y="-18.8%"
          width="126.6%"
          height="137.7%"
          filterUnits="objectBoundingBox"
          id="prefix__b"
        >
          <feGaussianBlur stdDeviation={50} in="SourceGraphic" />
        </filter>
      </defs>
      <path
        d="M2090.994 356.405L2091 1153H964.205c49.207-416.618 524.617-750.614 1126.79-796.595z"
        filter="url(#prefix__b)"
        transform="translate(-850 -253)"
        fill="url(#prefix__a)"
        fillRule="evenodd"
        fillOpacity={0.7}
        opacity={0.699}
      />
    </svg>
  )
}

export default OrangeCircle