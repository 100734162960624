import React from "react"
import BulletX from "../../styles/assets/icons/BulletX"
import BulletSmile from "../../styles/assets/icons/BulletSmile"
import OldScroll from "../../styles/assets/illustrations/oldscroll"
import NewCard from "../../styles/assets/illustrations/newcard"

const Comparison = () => {
  return (
    <div className="comparison">
      <div className="row">
        <div className="info-container left-container">
          <div className="top-heading">
            <h5>THE OLD WAY</h5>
          </div>
          <h2>Search and scroll endlessly.</h2>
          <div className="image-container">
            <OldScroll />
          </div>
          <div className="text-container">
            <div className="bullet-text-container">
              <div className="bullet-container"><BulletX /></div>
              <p><em>Word of mouth recommendations are inconsistent. </em>
                Friends casually mention appetizing restaurants, but you never remember to go.
              </p>
            </div>
            <div className="bullet-text-container">
              <div className="bullet-container"><BulletX /></div>
              <p><em>Untrustworthy reviews from strangers with dissimilar tastes. </em>
                Worry about fake or biased reviews.
              </p>
            </div>
            <div className="bullet-text-container">
              <div className="bullet-container"><BulletX /></div>
              <p><em>Difficulty in discovering new restaurants that you enjoy. </em>
                Trying new restaurants is hit or miss.
              </p>
            </div>
            <div className="bullet-text-container">
              <div className="bullet-container"><BulletX /></div>
              <p><em>Spend 15+ minutes researching where to eat. </em>
                Then feeling dissatisfied with your decision.
              </p>
            </div>
          </div>
        </div>
        <div className="info-container right-container">
          <div className="top-heading">
            <h5>THE NEW WAY</h5>
          </div>
          <h2>Discover and decide dynamically.</h2>
          <div className="image-container">
            <NewCard />
          </div>
          <div className="text-container">
            <div className="bullet-text-container">
              <div className="bullet-container"><BulletSmile /></div>
              <p><em>Easily access friends’ recommendations. </em>
                Never forget restaurants that friends have been to and the delicious dishes they recommend.
              </p>
            </div>
            <div className="bullet-text-container">
              <div className="bullet-container"><BulletSmile /></div>
              <p><em>Trusted reviews from friends with good tastes. </em>
                Never worry about the credibility of reviews again.
              </p>
            </div>
            <div className="bullet-text-container">
              <div className="bullet-container"><BulletSmile /></div>
              <p><em>Finding good food has never been easier. </em>
                Food recommendations tailored to your tastes and preferences.
              </p>
            </div>
            <div className="bullet-text-container">
              <div className="bullet-container"><BulletSmile /></div>
              <p><em>A fun, novel way of deciding where to eat. </em>
                Discover restaurants you’ll enjoy with friends.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Comparison
