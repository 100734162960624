import React from "react";

const BulletSmile = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1068 -1858)">
          <g transform="translate(1068 1858)">
            <circle cx="16" cy="16" r="16" fill="#FFC529"></circle>
            <path
              fill="#FFF"
              d="M15.986 21.479c2.726 0 5.745-.493 9.055-1.479-.672 2.028-2.074 3.536-4.206 4.523-3.198 1.48-6.447 1.48-9.537 0-2.06-.987-3.57-2.495-4.531-4.523 3.42.986 6.493 1.479 9.219 1.479z"
            ></path>
            <path
              fill="#FFF"
              d="M10.327 13.145c2.152 0 3.18 2.81 3.18 1.429 0-.7-.226-1.876-.79-2.736-.551-.837-1.525-1.367-2.39-1.367-.866 0-1.756.528-2.355 1.367-.613.86-.824 2.037-.824 2.736 0 1.38 1.028-1.429 3.18-1.429z"
            ></path>
            <path
              fill="#FFF"
              d="M21.327 13.145c2.152 0 3.18 2.81 3.18 1.429 0-.7-.226-1.876-.79-2.736-.551-.837-1.525-1.367-2.39-1.367-.866 0-1.756.528-2.355 1.367-.613.86-.824 2.037-.824 2.736 0 1.38 1.028-1.429 3.18-1.429z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BulletSmile;
