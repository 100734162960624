import * as React from "react"

const PurpleCircle = (props) => {
	return (
		<svg width={423} height={518} xmlns="http://www.w3.org/2000/svg" {...props}>
			<defs>
				<linearGradient
					x1="87.953%"
					y1="20.924%"
					x2="21.867%"
					y2="79.001%"
					id="prefix__c"
				>
					<stop stopColor="#AF6CFF" offset="0%" />
					<stop stopColor="#7928C0" offset="100%" />
				</linearGradient>
				<filter
					x="-35.1%"
					y="-51.5%"
					width="170.2%"
					height="203.1%"
					filterUnits="objectBoundingBox"
					id="prefix__d"
				>
					<feGaussianBlur stdDeviation={50} in="SourceGraphic" />
				</filter>
			</defs>
			<path
				d="M-111 863.882c218.679 30.449 389.285 145.964 427.136 291.115L-111 1155z"
				filter="url(#prefix__d)"
				transform="translate(0 -760)"
				fill="url(#prefix__c)"
				fillRule="evenodd"
				fillOpacity={0.7}
				opacity={0.749}
			/>
		</svg>
	)
}

export default PurpleCircle