import React from "react";

const OldScroll = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="136"
      viewBox="0 0 150 136"
    >
      <defs>
        <linearGradient
          id="linearGradient-1"
          x1="50%"
          x2="50%"
          y1="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="#EEF2F4" stopOpacity="0"></stop>
          <stop offset="100%" stopColor="#EEF2F4"></stop>
        </linearGradient>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-490 -1552)">
          <g transform="translate(490 1552)">
            <g>
              <rect
                width="150"
                height="9.765"
                x="0"
                y="0"
                fill="#D3D3D3"
                rx="4.882"
              ></rect>
              <g
                fill="#EEF2F4"
                fillRule="nonzero"
                transform="translate(137.5 2.17)"
              >
                <path d="M2.304 4.597c.531 0 .961-.1 1.351-.402l1.25 1.23.53-.525-1.229-1.225c.304-.39.402-.846.402-1.376a2.304 2.304 0 00-4.608 0 2.304 2.304 0 002.304 2.298zM2.303.71c.878 0 1.592.712 1.592 1.588a1.592 1.592 0 01-3.184 0c0-.876.714-1.588 1.592-1.588z"></path>
              </g>
              <rect
                width="45.652"
                height="20.615"
                x="0.543"
                y="17.36"
                fill="#D3D3D3"
                rx="2.036"
              ></rect>
              <rect
                width="45.652"
                height="20.615"
                x="51.63"
                y="17.36"
                fill="#D3D3D3"
                rx="2.036"
              ></rect>
              <rect
                width="45.652"
                height="20.615"
                x="103.261"
                y="16.817"
                fill="#D3D3D3"
                rx="2.036"
              ></rect>
              <rect
                width="110.326"
                height="5.967"
                x="0"
                y="42.315"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="32.065"
                height="5.967"
                x="0"
                y="52.622"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="19.022"
                height="5.967"
                x="36.413"
                y="52.622"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="50"
                height="5.967"
                x="60.326"
                y="52.622"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="148.913"
                height="5.967"
                x="0"
                y="62.929"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="45.652"
                height="20.615"
                x="0.543"
                y="77.034"
                fill="#D3D3D3"
                rx="2.036"
              ></rect>
              <rect
                width="45.652"
                height="20.615"
                x="51.63"
                y="77.034"
                fill="#D3D3D3"
                rx="2.036"
              ></rect>
              <rect
                width="45.652"
                height="20.615"
                x="103.261"
                y="76.492"
                fill="#D3D3D3"
                rx="2.036"
              ></rect>
              <rect
                width="110.326"
                height="5.967"
                x="0"
                y="101.989"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="32.065"
                height="5.967"
                x="0"
                y="112.297"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="19.022"
                height="5.967"
                x="36.413"
                y="112.297"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="50"
                height="5.967"
                x="60.326"
                y="112.297"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
              <rect
                width="148.913"
                height="5.967"
                x="0"
                y="122.604"
                fill="#D3D3D3"
                rx="1.221"
              ></rect>
            </g>
            <path
              fill="url(#linearGradient-1)"
              d="M0 92.571H148.855V136H0z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default OldScroll;
