import React from "react";

const NewCard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="151"
      height="136"
      viewBox="0 0 151 136"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1253 -1559)">
          <g transform="translate(1254 1559)">
            <path
              fill="#5C6C7F"
              d="M48.205 4.16a26.565 26.565 0 00-6.802 17.79v72.986c0 14.726 11.938 26.663 26.664 26.663h3.2l-5.837 2.728c-14.193 6.633-31.075.504-37.707-13.689a28.282 28.282 0 01-.02-.042l-25.07-53.88C-3.97 42.529 2.165 25.676 16.34 19.05z"
            ></path>
            <path
              fill="#5C6C7F"
              d="M129.198 0C140.687 0 150 9.313 150 20.802v75.215c0 11.489-9.313 20.802-20.802 20.802H67.54c-11.488 0-20.801-9.313-20.801-20.802V20.802C46.739 9.313 56.052 0 67.54 0h61.658zM98.546 22.597c-5.95 0-5.69-1.117-5.368.5.32 1.618 1.59 4.103 5.423 4.103 3.834 0 4.99-2.436 5.3-4.103.31-1.667.637-.5-5.355-.5zM103.495 12l-.06.146a47.665 47.665 0 01-.863 1.994c-.244.523-.452.925-.624 1.205l-.064.104c-.421.678-.708 1.067-1.406 1.695-.125.114-.264.235-.42.367l-.087.073c-.7.59-1.265 1.013-1.695 1.27-.066-.234-.1-.48-.101-.74-.001-.259.03-.552.094-.878l-.039.047c-.543.654-.891 1.177-1.046 1.57l-.006.013c-.155.403-.19 1.13-.105 2.179 0 .171-.472.322-1.073.478-.543.14-1.197.273-1.96.399.255.036.458.043.768.044h.2c.673 0 1.492.013 2.068-.226.616-.256 1.38-.771 2.293-1.545l.313-.289c.282-.26.518-.48.71-.662.388-.367 1.415-1.548 1.767-2.063l.03-.043c.172-.258.453-.724.842-1.4l-.016.043a5.956 5.956 0 01-.175.425l-.026.057c-.084.18-.21.467-.377.771-.179.326-.621.973-.938 1.343l-.273.317c-.23.267-.464.533-.724.803-.547.567-.797.832-1.33 1.25-.275.215-.511.428-.82.625-.29.185-.61.368-1.09.628h1.84c.845-.001 1.338-.025 1.69-.094.35-.068.648-.145 1.041-.383.282-.172.51-.386.684-.643-.103.06-.331.116-.684.165l-.038.005c-.332.044-.559.042-.681-.005l.05-.041c.656-.53.995-.81 1.48-1.329.484-.52.817-1.13 1.138-2.293.332-1.201.263-2.236.225-2.882-.037-.645-.183-1.398-.512-2.5zm-2.483 2l-.054.011c-.408.088-.7.175-.874.263-.273.138-.455.276-.59.552-.137.276-.097.263-.178.56-.058.212-.15.417-.276.614l.154-.064c.246-.103.406-.173.483-.212.076-.038.452-.228.54-.293.09-.066.248-.173.383-.3.106-.102.236-.246.324-.387.087-.141.103-.301.107-.338a1.183 1.183 0 00-.019-.406z"
            ></path>
            <rect
              width="71.126"
              height="6.648"
              x="62.861"
              y="38.241"
              fill="#E3EFFB"
              rx="1.891"
            ></rect>
            <rect
              width="71"
              height="6.648"
              x="62.861"
              y="62.387"
              fill="#E3EFFB"
              rx="1.891"
            ></rect>
            <rect
              width="20"
              height="13.296"
              x="62.861"
              y="85.533"
              fill="#E3EFFB"
              rx="1.891"
            ></rect>
            <rect
              width="20"
              height="13.296"
              x="88.36"
              y="85.533"
              fill="#E3EFFB"
              rx="1.891"
            ></rect>
            <rect
              width="20"
              height="13.296"
              x="113.861"
              y="85.533"
              fill="#E3EFFB"
              rx="1.891"
            ></rect>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default NewCard;
