import React, { useEffect } from "react"
import panel1 from '../../images/panel1.png'
import panel2 from '../../images/panel2.png'
import panel3 from '../../images/panel3.png'

const Features = () => {

  useEffect(() => {
    //preload images
    [panel1, panel2, panel3].forEach((panel) => {
      const img = new Image();
      img.src = panel;
    });
  }, []);

  return (
    <div className="features">

      <div className="features-container top-bottom-feature">
        <div className="panels-container">
          <div className="panel left-panel">
            <img className="image" src={panel1} alt="panel1"></img>
          </div>
        </div>
        <div className="tabs-container">
          <div className="tab">
            <h2>Discover restaurants with your friends.</h2>
            <p>Check out where your friends are eating and what they recommend — trusted reviews and restaurant information at your fingertips.</p>
          </div>
        </div>
      </div>

      <div className="features-container middle-feature">
        <div className="panels-container middle-panel">
          <div className="panel">
            <img className="image" src={panel2} alt="panel2"></img>
          </div>
        </div>
        <div className="tabs-container">
          <div className="tab">
            <h2>Grow and develop your food network.</h2>
            <p>Never forget restaurants that friends have been to and recommend. Easily access friends’ recommendations.</p>
          </div>
        </div>
      </div>

      <div className="features-container top-bottom-feature">
        <div className="panels-container">
          <div className="panel">
            <img className="image" src={panel3} alt="panel3"></img>
          </div>
        </div>
        <div className="tabs-container">
          <div className="tab">
            <h2>Save and share your restaurants.</h2>
            <p>Upload your restaurant experiences. Keep a record of everywhere you’ve eaten in your profile.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Features
