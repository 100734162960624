import React from "react";

const BulletX = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-278 -1858)">
          <g transform="translate(278 1858)">
            <circle cx="16" cy="16" r="16" fill="#999DA0"></circle>
            <g
              fill="#FFF"
              fillRule="nonzero"
              transform="rotate(-45 20.95 4.05)"
            >
              <path d="M16.875 7.875h-6.75v-6.75C10.125.375 9.75 0 9 0S7.875.375 7.875 1.125v6.75h-6.75C.375 7.875 0 8.25 0 9s.375 1.125 1.125 1.125h6.75v6.75C7.875 17.625 8.25 18 9 18s1.125-.375 1.125-1.125v-6.75h6.75C17.625 10.125 18 9.75 18 9s-.375-1.125-1.125-1.125z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default BulletX;