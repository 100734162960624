import React from "react"
import PurpleCircle from '../../styles/assets/illustrations/purplecircle'
import OrangeCircle from '../../styles/assets/illustrations/orangecircle'

import Hero from "./hero"
import Comparison from "./comparison"
import Features from "./features"

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Home = () => {
  return (
    <div className="section" id="home">
      <div className="container">
        <div className="home">
          <Hero />
          <Features />
          <Comparison />
        </div>
      </div>
      <div className="orange-circle">
        <OrangeCircle />
      </div>
      <div className="purple-circle">
        <PurpleCircle />
      </div>
    </div>
  )
}

export default Home
